<template>
    <header v-if="brand_slug != 'turbotlumaczenia_pl'">
        <nav aria-label="" aria-labelledby="" class="navbar navbar-expand-md navbar-static-top dark"
             v-bind:class="this.$route.name">
            <div class="container">
                <router-link :to="{name:'home', params: { locale: locale }}" class="navbar-brand vertical-center ml-3" :title="brand_slug">
                    <img :alt="brand_slug" :src="require(`../../img/${brand_slug}/logo-white.svg`)">
                </router-link>
                <ul class="mb-0 mr-3">
                    <Locale></Locale>
                </ul>
            </div>
        </nav>
        <div class="container" v-bind:class="this.$route.name">
            <nav aria-labelledby="" aria-label="" class="navbar navbar-expand-md navbar-light">
                <router-link :to="{name:'home', params: { locale: locale }}" class="navbar-brand vertical-center" :title="brand_slug">
                    <img :src="require(`../../img/${brand_slug}/logo.svg`)" />
                </router-link>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <ul class="navbar-nav ml-md-auto" v-if="loggedIn===true">
                        <li class="nav-item">
                            <router-link :to="{name:'order-list', params: { locale: locale }}" class="nav-link" :title="$t('Orders')">
                                {{ $t('Orders') }}
                                <div class="bottom-bar"></div>
                            </router-link>
                        </li>
                        <li class="nav-item ">
                            <router-link :to="{name:'invoice-list', params: { locale: locale }}" class="nav-link" :title="$t('Invoices')">
                                {{ $t('Invoices') }}
                                <div class="bottom-bar"></div>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name:'payment-list', params: { locale: locale }}" class="nav-link" :title="$t('Payments')">
                                {{ $t('Payments') }}
                                <div class="bottom-bar"></div>
                            </router-link>
                        </li>
                        <b-nav-item-dropdown
                            right
                        >
                            <template #button-content>
                                <b-icon-arrow-clockwise font-scale="1" animation="spin"
                                                        v-if="currency_amount_decimal==''"
                                ></b-icon-arrow-clockwise>
                                {{ currency_amount_decimal }}
                                {{ display_currency_code }}
                                <div class="bottom-bar"></div>
                            </template>
                            <b-dropdown-item>
                                <router-link :to="{name:'top-up', params: { locale: locale }}"
                                             class="dropdown-item"
                                             :title="$t('Top up your account')">
                                    {{ $t('Top up your account') }}
                                </router-link>
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <li class="nav-item">
                            <router-link :to="{name:'settings', params: { locale: locale }}" class="nav-link" :title="$t('Account settings')">
                                {{ $t('Account settings') }}
                                <div class="bottom-bar"></div>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link logout" :title="$t('Log out')" @click.prevent="logout">
                                {{ $t('Log out') }}
                                <div class="bottom-bar"></div>
                            </a>
                        </li>
                        <Locale></Locale>
                    </ul>
                    <ul class="navbar-nav ml-md-auto" v-else>
                        <li>
                            <router-link :to="{name:'login', params:{ locale: locale }}" class="nav-link" :title="$t('Log in')">
                                {{ $t('Log in') }}
                                <div class="bottom-bar"></div>
                            </router-link>
                        </li>
                        <Locale></Locale>
                    </ul>
                </b-collapse>
            </nav>
        </div>
    </header>
</template>
<script>
import Locale from "./Locale";
import {
    BDropdownItem, BNavItemDropdown, BIconArrowClockwise,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
} from "bootstrap-vue";
import Helpers from "../helpers/Helpers";
import i18n from "../../../common/js/i18n";
import BrandConfig from "../BrandConfig";
export default {
    name: 'Nav',
    components: {
        Locale, BDropdownItem, BNavItemDropdown, BIconArrowClockwise,
        BNavbar,
        BNavbarBrand,
        BNavbarToggle,
        BCollapse,
    },
    directives: {
        'b-navbar': BNavbar,
        'b-navbar-brand': BNavbarBrand,
        'b-navbar-toggle': BNavbarToggle,
        'b-collapse': BCollapse,
        'b-dropdown-item': BDropdownItem,
        'b-nav-item-dropdown': BNavItemDropdown,
        'b-icon-arrow-clockwise': BIconArrowClockwise
    },
    data() {
        return {
            locale: i18n.locale,
            currency_amount_decimal: "",
            display_currency_code: '',
            brand_slug: BrandConfig.brandName
        }
    },
    created() {

        if(this.loggedIn){
            this.$root.$on("refresh-customer", () => {
                this.currency_amount_decimal = "";
                this.$store.dispatch("authorized/customer").then((r) => {
                    this.data();
                })
            });
        }

        this.$root.$on("customer-loaded", (() => {
            this.data();
        }));
    },
    mounted() {
        if (this.loggedIn) {
            this.data();
        }
        this.$root.$on("locale-changed",(locale)=>{
            this.locale = locale
        })
    },
    computed: {

        loggedIn: {
            get() {
                return this.$store.getters["common/isAuthorized"]
            },
            set(value) {
                this.$store.commit('updateIsAuthenticated', value)
            }
        },
    },
    methods: {
        logout() {
            Helpers.flushAuth(this.$store).then(() => {
                this.$router.push({name: "home"});
            });
        },
        data() {
            const customer = this.$store.getters["authorized/customer"];
            if (customer) {
                const accountBalance = customer.data.account_balance;
                this.currency_amount_decimal = accountBalance.currency_amount_decimal;
                this.display_currency_code = accountBalance.currency_code;
            }
        }
    }
}
</script>

<style lang="scss">
@media (prefers-reduced-motion: reduce) {
    .b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
        animation: 2s infinite linear normal b-icon-animation-spin !important;
        -webkit-animation: 2s infinite linear normal b-icon-animation-spin !important;
    }
}

@media (max-width: 750px) {
    ul {
        &.navbar-nav {
            li {
                a {
                    &.nav-link {
                        text-align: right;
                    }
                }
            }
        }
    }
}

header {
    .dark {
        display: none;

        &.login, &.register, &.password-reset, &.password-forgot {
            display: block !important;
        }

        ul {
            list-style-type: none;
        }

        @media (max-width: 750px) {
            #languages-nav-dropdown {
                a.nav-link {
                    padding-right: 0px;
                }
            }
        }

        .dropdown-toggle::after {
            color: #FFF;
        }
    }

    .container {
        &.login, &.register, &.password-reset, &.password-forgot {
            display: none !important;
        }
    }

}

nav {
    a {
        &.logout {
            cursor: pointer;
        }
    }

    .router-link-active {
        color: #005aff !important;

        .bottom-bar {
            display: block !important;
        }
    }
}
</style>
