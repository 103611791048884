import _ from "lodash";
import BrandConfig from "../BrandConfig";
import axios from "axios";
import i18n from "../../../common/js/i18n";
import Helpers from "../helpers/Helpers";
const CancelToken = axios.CancelToken;
let cancel: (() => void) | undefined;


export default {

    namespaced: true,

    state: {
        accessToken: null,
        languages: {},
        countries: {},
        categories: {},
        isLoggedIn: false,

        authFailure: false,
        error: null,

        write: [],
        sworn: {},
        proofreading: {},

        expiredAt: null,

        brandMetas: null,
        currencies: [],
        brandId: null
    },

    mutations: {
        clearAuthData(state: { accessToken: null; accessExpiration: null; isLoggedIn: boolean; }) {
            state.accessToken = null;
            state.accessExpiration = null;
            state.isLoggedIn = false;
        },
        saveAuth(state: { accessToken: any; }, accessToken: any) {
            state.accessToken = accessToken;
        },
        saveExpiredAt(state: { expiredAt: any; }, expiredAt: any) {
            state.expiredAt = expiredAt;
        },
        saveLanguages(state: { languages: any; }, data: any) {
            state.languages[i18n.locale] = data;
        },
        saveSworn(state: { sworn: any; }, data: any) {
            state.sworn[i18n.locale] = data;
        },
        saveProofreading(state: { proofreading: any; }, data: any) {
            state.proofreading[i18n.locale] = data;
        },
        countries(state: { countries: any; }, countries: any) {
            state.countries[i18n.locale] = countries;
        },
        saveCategories(state: { categories: any }, categories: any) {
            state.categories[i18n.locale] = categories;
        },
        isLogged(state: { isLoggedIn: boolean; }) {
            state.isLoggedIn = true;
        },
        saveBrandMetas(state: { brandMetas: any }, data: any) {
            if (data.data.meta) {
                state.brandMetas = data.data.meta;
            }
        },
        saveBrandId(state: { brandId: number }, id: number) {
            state.brandId = id;
        },
        authFailure(state: { error: any; authFailure: boolean; }, error: any) {
            state.error = error;
            state.authFailure = true;
        },
        saveCurrencies(state: { currencies: any[] }, data: any) {
            state.currencies = data;
        },
    },
    actions: {
        logout({commit}: any) {
            commit("clearAuthData");
        },
        auth({commit, getters}: any) {
            if (!getters.accessToken) {
                return axios.post(BrandConfig.access_token_url, {
                    'x-for': btoa(BrandConfig.brandName!).replace("==", "")
                }).then((response) => {
                    const parsedDate = new Date(Date.now())
                    const expiredAt = parsedDate.getTime() + (1000 * response.data.expires_in);
                    commit("saveAuth", response.data.access_token);
                    commit("saveExpiredAt", expiredAt);
                });
            }
        },
        sworn({commit, getters, state}: any, data: { reload: boolean, currencyId: number }) {
            if (state.sworn.length > 0 && state.sworn.hasOwnProperty(i18n.locale) && !data.reload) {
                return state.sworn[i18n.locale];
            } else {
                return axios.get(BrandConfig.api_url + '/api/v2/service/write/'+data.currencyId, getters.authorization).then(response => {
                    const l = Helpers.pairs(response.data.data.pairs, 'sworn', 'page');
                    commit("saveSworn", l);
                    return l;
                });
            }
        },
        proofreading({commit, getters, state}: any, data: { reload: boolean, currencyId: number }) {
            if (state.proofreading.length > 0 && state.proofreading.hasOwnProperty(i18n.locale) && !data.reload) {
                return state.proofreading[i18n.locale];
            } else {
                return axios.get(BrandConfig.api_url + '/api/v2/service/proofreading/'+data.currencyId, getters.authorization).then(response => {
                    const l = Helpers.pairs(response.data.data.pairs, 'regular', 'word');
                    commit("saveProofreading", l);
                    return l;
                });
            }
        },
        languages({commit, getters, state}: any, data: { reload: boolean, currencyId: number }) {
            if (state.languages && state.languages.hasOwnProperty(i18n.locale) && !data.reload) {
                return state.languages[i18n.locale];
            } else {
                if (cancel != undefined) {
                    cancel();
                }
                return axios.get(
                    BrandConfig.api_url + '/api/v2/service/write/'+data.currencyId,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            cancel = c;
                        }),
                        headers: getters.authorization.headers
                    }
                ).then(response => {
                    const l = Helpers.pairs(response.data.data.pairs, 'regular', 'word');
                    commit("saveLanguages", l);
                    return l;
                });
            }
        },
        countries({commit, getters, state}: any, reload: boolean = false) {
            if (state.countries && state.countries.hasOwnProperty(i18n.locale) && !reload) {
                return state.countries[i18n.locale];
            } else {
                return axios.get(BrandConfig.api_url + "/api/v2/country", getters.authorization).then((response) => {
                    const countries = _.map(response.data.data, (country) => {
                        return {value: country.id, text: country.name};
                    })
                    commit('countries', countries);
                    return countries;
                });
            }
        },
        categories({commit, getters, state}: any, reload: boolean = false) {
            if (state.categories && state.categories.hasOwnProperty(i18n.locale) && !reload) {
                return state.categories[i18n.locale];
            } else {
                return axios.get(BrandConfig.api_url + "/api/v2/brand/" + BrandConfig.brandName + "/categories",
                    getters.authorization
                ).then((response) => {
                    const categories = _.map(response.data.data, (category) => {
                        return {id: category.category.id, text: category.category.name};
                    }).sort(function(a,b){
                        return b.text - a.text;
                    });
                    commit('saveCategories', categories);
                    return categories;

                });
            }
        },
        login({commit}: any, credentials: { email: any; password: any; }) {
            return axios.post(BrandConfig.api_url + "/api/v2/oauth2/access_token", {
                "username": credentials.email,
                "password": credentials.password,
                "grant_type": "password",
            }).then((response) => {
                if (response) {
                    const parsedDate = new Date(Date.now())
                    const expiredAt = parsedDate.getTime() + (1000 * response.data.expires_in);
                    commit("saveAuth", response.data.access_token, expiredAt);
                    commit("isLogged");
                    return response;
                }
            }, (error) => {
                commit("authFailure", error.response);
                return error;
            }).catch((error) => {
                Helpers.captureError(error);
                commit("authFailure", error.response);
                return error;
            });
        },
        brandMetas({commit, getters, state}: any, reload: boolean = false) {
            if (state.brandMetas && !reload) {
                return state.brandMetas;
            } else {
                return axios.get(BrandConfig.api_url + "/api/v2/brand/" + BrandConfig.brandName,
                    getters.authorization
                ).then((response) => {
                    if (response.data.data.meta.length <= 0) {
                        throw new Error("Brand meta for " + BrandConfig.brandName + " is empty.");
                    } else {
                        commit("saveBrandMetas", response.data);
                        commit("saveBrandId", response.data.data.id);
                    }
                });
            }
        },
        currencies({commit, getters, state}: any) {
            return (state.currencies.length > 0) ? state.currencies :
                axios.get(BrandConfig.api_url + "/api/v2/dict/currencies",
                    getters.authorization
                ).then((r) => {
                    commit("saveCurrencies", r.data.data);
                });
        }
    },
    getters: {
        accessToken(state: { accessToken: any; }) {
            return state.accessToken;
        },
        languages(state: { languages: any; }) {
            return state.languages[i18n.locale];
        },
        sworn(state: { sworn: any; }) {
            return state.sworn[i18n.locale];
        },
        proofreading(state: { proofreading: any; }) {
            return state.proofreading[i18n.locale];
        },
        write(state: { write: any; }) {
            return state.write;
        },
        countries(state: { countries: any; }) {
            return state.countries[i18n.locale];
        },
        categories(state: { categories: any; }) {
            return state.categories[i18n.locale];
        },
        authorization(state: { accessToken: string; }) {
            return {
                headers: {
                    Authorization: 'Bearer ' + state.accessToken,
                    'Accept-Language': i18n.locale
                }
            };
        },
        isAuthorized(state: { isLoggedIn: any; }) {
            return state.isLoggedIn;
        },
        authError(state: { error: any; }) {
            return state.error;
        },
        brandMetas(state: { brandMetas: any; }) {
            return state.brandMetas;
        },
        brandMetaItem: (state: { brandMetas: any; }) => (key: any) => {
            return state.brandMetas[key];
        },
        currencies(state: { currencies: any[] }): any[] {
            return state.currencies;
        },
        brandId(state: { brandId: number }): number {
            return state.brandId;
        }
    },
}
