import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "../../common/js/i18n";
import store from "./store/Store";
import BrandConfig from "./BrandConfig";

Vue.use(VueRouter);
const locale = i18n.locale;

const guard = async (to: any, from: any, next: any) => {
    if(!store.getters["common/isAuthorized"]) {
        next('/');
    }
    next();
};
var routes = [];
if(BrandConfig.brandName == 'turbotlumaczenia_pl') {
    routes = [
        {
            path: '/',
            component: () => import("./views/MTDemo.vue"),
            name: 'mtdemo',
            meta: {
                requiresAuth: true
            }        }
    ]
} else {
    routes = [
        {
            path: '*',
            redirect: '/' + locale + '/order/create'
        },
        {
            path: '/',
            alias: '/:locale',
            component: () => import("./views/OrderCreate.vue"),
            name: 'home',
        },
        {
            path: '/:locale/login',
            component: () => import("./views/Login.vue"),
            name: 'login',
        },
        {
            path: '/:locale/order/create',
            component: () => import("./views/OrderCreate.vue"),
            name: 'order-create'
        },
        {
            path: '/:locale/order/write',
            component: () => import("./views/Write.vue"),
            name: 'write'
        },
        {
            path: '/:locale/order/certified',
            component: () => import("./views/Sworn.vue"),
            name: 'sworn'
        },
        {
            path: '/:locale/order/proofreading',
            component: () => import("./views/Proofreading.vue"),
            name: 'proofreading'
        },
        {
            path: '/:locale/order/package',
            component: () => import("./views/Packages.vue"),
            name: 'package'
        },

        {
            path: '/:locale/register',
            component: () => import("./views/Register.vue"),
            name: 'register'
        },
        {
            path: '/:locale/password-forgot/:token',
            component: () => import("./views/PasswordForgot.vue"),
            name: 'password-forgot'
        },
        {
            path: '/:locale/password-reset',
            component: () => import("./views/PasswordReset.vue"),
            name: 'password-reset'
        },
        {
            path: '/:locale/password/verification/:email/:hash',
            component: () => import("./views/PasswordVerification.vue"),
            name: 'password-verification'
        },
        {
            path: '/:locale/password/verification/:email/:hash/:reset_password_hash',
            component: () => import("./views/PasswordVerification.vue"),
            name: 'password-verification'
        },
        {
            path: '/:locale/account/orders',
            beforeEnter: guard,
            component: () => import("./views/Orders.vue"),
            name: 'order-list',
        },
        {
            path: '/:locale/account/order/:hash',
            component: () => import("./views/Order.vue"),
            name: 'order'
        },
        {
            path: '/:locale/account/invoices',
            beforeEnter: guard,
            component: () => import("./views/Invoices.vue"),
            name: 'invoice-list'
        },
        {
            path: '/:locale/account/invoices/:id',
            beforeEnter: guard,
            component: () => import("./views/Invoice.vue"),
            name: 'invoice'
        },
        {
            path: '/:locale/account/payments',
            beforeEnter: guard,
            component: () => import("./views/Payments.vue"),
            name: 'payment-list'
        },
        {
            path: '/:locale/account/settings',
            beforeEnter: guard,
            component: () => import("./views/Settings.vue"),
            name: 'settings'
        },
        {
            path: '/:locale/account/prepaid',
            beforeEnter: guard,
            component: () => import("./views/Topup.vue"),
            name: 'top-up'
        },
        {
            path: '/:locale/account/prepaid/summary',
            component: () => import("./views/PrepaidSummary.vue"),
            name: 'top-up-summary'
        },
        {
            path: '/:locale/payment/confirmation',
            component: () => import("./views/PaymentConfirmation.vue"),
            name: 'payment-confirmation'
        },
        {
            path: '/:locale/payment/success',
            component: () => import("./views/PaymentSuccess.vue"),
            name: 'payment-success'
        },
        {
            path: '/:locale/payment/error',
            component: () => import("./views/PaymentCancel.vue"),
            name: 'payment-error'
        },
        {
            path: '/:locale/orders-created/:id/:creationtype',
            component: () => import("./views/OrderCreationInfo.vue"),
            name: 'order-created'
        },
        {   path: '/:locale/orders/:cart_hash/:order_hash',
            component: () => import("./views/OrderSummary.vue"),
            name: 'order-summary-order-hash'
        },
        {   path: '/:locale/orders/:cart_hash',
            component: () => import("./views/OrderSummary.vue"),
            name: 'order-summary-cart-hash'
        }
    ]
}

export default new VueRouter({
    mode: 'history',
    routes,
});


