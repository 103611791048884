class Storage {
    private readonly ver: any;

    constructor() {
        this.ver = process.env.VUE_APP_CI_COMMIT_SHORT_SHA;
    }

    setItem(key: string, value: string) {
        localStorage.setItem(this.ver + "." + key, value);
    }

    getItem(key: string, def: string|any = null) {
        const value = localStorage.getItem(this.ver + "." + key);
        return (value) ? value : def;
    }

    removeItem(key: string) {
        return localStorage.removeItem(this.ver + "." + key);
    }

    clear() {
        localStorage.clear();
        sessionStorage.clear();
    }
}

export default new Storage();
