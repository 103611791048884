import BrandConfig from "../BrandConfig";
import _ from "lodash";

class GtmService {
    private static instance: GtmService;

    private gtag: any;
    private currency: string;

    constructor() {
        this.currency = "";
    }

    public static getInstance(): GtmService {
        if (!GtmService.instance) {
            GtmService.instance = new GtmService();
        }

        return GtmService.instance;
    }

    public watch(root: any, gtag: any) {
        this.gtag = gtag;
        root.$on("view_cart", (options: any) => {
            this.viewCart(options.cart);
        });
        root.$on("begin_checkout", (options: any) => {
            this.beginCheckout(options.cart);
        });

        root.$on("view_item", (options: any) => {
            _.forEach(options.cart.items, (item) => {
                let products: { item_name: string; price: any; item_category: string; item_variant: string, quantity: number; item_id: string; item_brand: any; currency: string, item_list_position: number }[] = [];
                let index = 0;
                const product = this.product(item, index);
                products.push(product)

                this.viewItem(products);
            });
        });
        root.$on("purchase", (options: any) => {
            this.purchase(options.hash, options.cart);
        });
    }

    currencyCode(cart: any) {
        return cart.items[0].quotation.price_gross.currency_code;
    }

    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    products(cart: any) {
        let products: { item_name: string; price: any; item_category: string; item_variant: string, quantity: number; item_id: string; item_brand: any; currency: string, item_list_position: number }[] = [];
        let currency = null;
        let index = -1;
        _.forEach(cart.items, (item) => {
            currency = item.quotation ? item.quotation.price_gross.currency_code : null;
            index++;

            const product = this.product(item, index);
            product.item_id = this.checksum(product);
            products.push(product)
        });
        if(currency) {
            this.gtag('set', {'currency': currency});
        }
        return products;
    }

    product(item: any, index: number) {
        let currency = item.quotation ? item.quotation.price_gross.currency_code : ''
        return {
            'item_id': "",
            'item_name': item.public_name,
            'currency': currency,
            'coupon': '',
            'discount': 0.00,
            'item_brand': BrandConfig.brandName,
            'price': item.quotation ? item.quotation.price_gross.currency_amount_decimal : 0.00,
            'item_variant': item.variant_name ?? '',
            'item_category': 'General',
            'item_list_position': index,
            'quantity': 1,
        };
    }

    private viewCart(cart: { items_without_quotation?: any; items?: any; }) {
        let currency = cart.items[0].quotation ? cart.items[0].quotation.price_gross.currency_code : "";
        const products = this.products(cart);
        let total_price = this.totalPrice(products);

        this.gtag('event', 'view_cart', {
            currency: currency,
            value: total_price,
            items: products
        });
    }

    private viewItem(products: any) {
        this.gtag('event', 'view_item', {
            items: products
        });
    }

    private purchase(hash: string, cart: any) {
        const products = this.products(cart);

        let tax_total = parseFloat("0.0");
        let total_price = this.totalPrice(products);
        _.forEach(cart.items, (item) => {
            tax_total += parseFloat(item.quotation.vat.currency_amount_decimal);
        });

        this.gtag('event', 'purchase', {
            "transaction_id": hash,
            "affiliation": BrandConfig.brandName,
            "value": parseFloat(total_price.toString()).toFixed(2),
            "currency": cart.items[0].quotation.price_gross.currency_code,
            "tax": tax_total,
            "shipping": 0,
            "items": products
        });
    }

    private beginCheckout(cart: any) {
        this.gtag('event', 'begin_checkout', {
            "items": this.products(cart),
        });
    }

    private totalPrice(products: any) {
        let total_price = parseFloat("0.0");
        _.forEach(products, (product) => {
            total_price += parseFloat(product.price);
        });
        return total_price;
    }

    checksum(obj: any): string { // exaple return: P-642653559
        const keys = Object.keys(obj).sort();
        let output = [], prop;
        for (let i = 0; i < keys.length; i++) {
            prop = keys[i];
            output.push(prop);
            output.push(obj[prop]);
        }
        const s = JSON.stringify(output);
        return "P" + s.split("").reduce(function (a, b) {
            a = ((a << 5) - a) + b.charCodeAt(0);
            return a & a
        }, 0).toString();
    }

}

export default GtmService.getInstance();
