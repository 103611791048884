<template>
    <div class="preloader row">
        <div class="preloader-logo d-inline-flex mx-auto">
            <img :src="require(`../../img/${brand_slug}/logo.svg`)" />
        </div>
    </div>
</template>
<script>
import BrandConfig from "../BrandConfig";

export default {
    name: 'Overlay',
    data() {
        return {
            brand_slug: BrandConfig.brandName
        }
    }
}
</script>

<style scoped lang="scss">

.preloader {
    min-height: 79vh;
    .preloader-logo {
        width: 125px;
        img {
            max-width: 100%;
        }
    }
}
</style>
