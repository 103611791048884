import axios from "axios";
import BrandConfig from "../BrandConfig";
import i18n from "../../../common/js/i18n";
import Storage from "../store/Storage";
import Helpers from "../helpers/Helpers";
import Store from "../store/Store";

class LocaleService {

    private $store: typeof Store;

    constructor(store: typeof Store) {
        this.$store = store;
    }

    updateCustomerLocale(locale: string) {
        if (this.$store.getters["common/isAuthorized"]) {
            return axios.put(BrandConfig.api_url + "/api/v2/customer/current", {
                    locale: locale
                },
                this.$store.getters["authorized/headers"]
            );
        }
    }

    changeLocale(toLocale: string) {
        const fromLocale = i18n.locale;
        Storage.setItem('locale', toLocale);
        return this.$store.dispatch("common/brandMetas", true)
            .catch((r) => {
                Helpers.captureError(r);
                toLocale = fromLocale;
            })
            .then(() => {
                Storage.setItem('locale', toLocale);
                i18n.locale = toLocale;
                return toLocale;
            });
    }
}

export default LocaleService;
