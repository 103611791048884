import Helpers from "../helpers/Helpers";
import BrandConfig from "../BrandConfig";
import _ from "lodash";
import axios from "axios";
import i18n from "../../../common/js/i18n";
import SecureLS from "secure-ls";

export default {
    namespaced: true,
    state: {
        user: null,
        customer: null,
        profile: null,
        prepaidAmount: null,
        invoices: [],
        payments: [],
        orders: [],
        hash: null,
        accessToken: null,
    },
    mutations: {
        clearData(state: { user: null; customer: null; prepaidAmount: null; invoices: never[]; payments: never[]; orders: never[]; profile: null; }) {
            state.user = null;
            state.customer = null;
            state.prepaidAmount = null;
            state.invoices = [];
            state.payments = [];
            state.orders = [];
            state.user = null;
            state.profile = null;

            sessionStorage.clear();
        },
        invoices(state: { invoices: { [x: string]: any; }; }, object: { keyname: string | number; data: any; }) {
            state.invoices[object.keyname] = object.data;
        },
        payments(state: { payments: { [x: string]: any; }; }, object: { keyname: string | number; data: any; }) {
            state.payments[object.keyname] = object.data;
        },
        orders(state: { orders: { [x: string]: any; }; }, object: { keyname: string | number; data: any; }) {
            state.orders[object.keyname] = object.data;
        },
        saveUser(state: { user: any; }, data: any) {
            state.user = data;
        },
        saveProfile(state: { profile: any; }, data: any) {
            state.profile = data;
        },
        saveCustomer(state: { customer: any; }, data: any) {
            state.customer = data;
        },
        setAccessToken(state: { accessToken: any; }, data: any) {
            state.accessToken = data;
        }
    },

    actions: {
        prepaid({commit, state}: any, amount: string) {
            return new Promise(function (resolve) {
                sessionStorage.setItem('prepaid_amount', amount);
                state.prepaidAmount = amount;
                resolve(amount);
            });
        },
        logout({commit}: any) {
            commit("clearData");
        },
        user({commit, getters}: any) {
            return axios.get(BrandConfig.api_url + "/api/v2/user/current", getters.headers).then((response) => {
                commit("saveUser", response.data);
                return response.data;
            });
        },
        profile({commit, getters, state}: any, reload: boolean = false) {
            if (state.profile && !reload) {
                return state.profile;
            } else {
                return axios.get(BrandConfig.api_url + "/api/v1/profile", getters.headers).then((response) => {
                    commit("saveProfile", response.data);
                });
            }
        },
        customer({commit, getters}: any) {
            return axios.get(BrandConfig.api_url + "/api/v2/customer/current", getters.headers).then((response) => {
                commit("saveCustomer", response.data);
            });
        },
        invoices({commit, getters, state}: any, key: string) {
            const url = BrandConfig.api_url + "/api/v2/customer-invoice";
            return axios.get(url + "?" + key, getters.headers).then(
                (response) => {
                    commit('invoices', {keyname: key, data: response.data});
                    return response.data;
                }
            );
        },
        payments({commit, getters, state}: any, key: string) {
            const url = BrandConfig.api_url + "/api/v1/payment";
            return axios.get(url + "?" + key, getters.headers).then(
                (response) => {
                    commit('payments', {keyname: key, data: response.data});
                    return response.data;
                }
            );
        },
        orders({commit, getters, state}: any, key: string) {
            const url = BrandConfig.api_url + "/api/v2/order";
            return axios.get(url + "?" + key, getters.headers).then(
                (response) => {
                    commit('orders', {keyname: key, data: response.data});
                    return response.data;
                }
            );
        },
    },

    getters: {
        user(state: { user: any; }) {
            return state.user;
        },
        customer(state: { customer: any; }) {
            return state.customer;
        },
        profile(state: { profile: any; }) {
            return state.profile;
        },
        prepaidAmount(state: { prepaidAmount: any; }) {
            return sessionStorage.getItem('prepaid_amount') || state.prepaidAmount;
        },

        invoices(state: { invoices: any; }) {
            return state.invoices;
        },
        payments(state: { payments: any; }) {
            return state.payments;
        },
        orders(state: { orders: any; }) {
            return state.orders;
        },
        headers(state: { accessToken: any; }) {
            let accessToken = null;
            if (state.accessToken) {
                accessToken = state.accessToken;
            } else {
                const ls = new SecureLS({isCompression: false}),
                    key = Helpers.storageVersion() + '.' + process.env.VUE_APP_CI_COMMIT_SHORT_SHA,
                    item = process.env.NODE_ENV != 'development'
                        ? ls.get(key)
                        : localStorage.getItem(key);
                accessToken = JSON.parse(item).common.accessToken;
            }

            return {
                headers: {
                    Authorization: 'Bearer ' + accessToken,
                    'Accept-Language': i18n.locale
                }
            }
        }
    },
}
