import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Helpers from "../helpers/Helpers";

import SecureLS from "secure-ls";
import Common from "./Common";
import Authorized from "./Authorized";

const
    ls = new SecureLS({isCompression: false}),
    productionStorage = {
        getItem: (key: string) => {
            return ls.get(Helpers.storageVersion() + '.' + key);
        },
        setItem: (key: string, value: any) => ls.set(Helpers.storageVersion() + '.' + key, value),
        removeItem: (key: string) => ls.remove(Helpers.storageVersion() + '.' + key)
    },
    developmentStorage = {
        getItem: (key: string) => {
            return localStorage.getItem(Helpers.storageVersion() + '.' + key);
        },
        setItem: (key: string, value: string) => localStorage.setItem(Helpers.storageVersion() + '.' + key, value),
        removeItem: (key: string) => localStorage.removeItem(Helpers.storageVersion() + '.' + key)
    };

const storage = process.env.NODE_ENV != 'development' ? productionStorage : developmentStorage;

Vue.use(Vuex);
export default new Vuex.Store({
    plugins: [
        createPersistedState({
            paths: ['common','authorized'],
            key: process.env.VUE_APP_CI_COMMIT_SHORT_SHA,
            storage: storage,
        })
    ],
    modules: {
        common: Common,
        authorized: Authorized
    },
});
