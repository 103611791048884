import Vue from 'vue'
import VueI18n from 'vue-i18n'

import Storage from "../../default/js/store/Storage";
import BrandConfig from "../../default/js/BrandConfig";
import localeDe from '../../../../resources/lang/de.json'
import localeEn from '../../../../resources/lang/en.json'
import localePl from '../../../../resources/lang/pl.json'
import localeUa from '../../../../resources/lang/ua.json'

Vue.use(VueI18n)



function loadLocaleMessages() {

    const
        messages: VueI18n.LocaleMessages = {
            de: localeDe,
            en: localeEn,
            pl: localePl,
            ua: localeUa
        };

    return messages
}


const
    url = new URL(window.location.toString()),
    path = url.pathname.split('/'),
    brand_slug = BrandConfig.brandName,
    siteLocale = path[1] ? path[1] : Storage.getItem('locale', BrandConfig.default_locale[brand_slug as keyof typeof BrandConfig.default_locale]);

if(!Storage.getItem('locale')) {
    Storage.setItem('locale',siteLocale);
}

export default new VueI18n({
    // localeDe: LocaleDe,
    // localeEn: LocaleEn,
    locale: process.env.VUE_APP_I18N_LOCALE || siteLocale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || siteLocale,
    messages: loadLocaleMessages(),
})

