import BrandConfig from "../BrandConfig";
import * as Sentry from "@sentry/vue";
import _ from "lodash";
import Store from "../store/Store";


class Helpers {

    captureError(exception: any) {
        if (process.env.NODE_ENV !== 'development') {
            Sentry.captureException(exception);
        } else {
            console.log(exception);
        }
    }

    flushAuth(store: typeof Store){
        return Promise.all([
            store.dispatch("common/logout"),
            store.dispatch("authorized/logout"),
            store.dispatch("common/auth")
        ]);
    }

    captureMessage(warning: any) {
        if (process.env.NODE_ENV !== 'development') {
            Sentry.captureMessage(warning);
        } else {
            console.log(warning);
        }
    }

    randomString(len: number) {
        const p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        return [...Array(len)].reduce(
            (a) => a + p[~~(Math.random() * p.length)],
            ""
        );
    }

    storageVersion() {
        const version = process.env.VUE_APP_CI_COMMIT_SHORT_SHA;
        const dt = new Date(),
            key = version + ".expired_at";
        dt.setHours(dt.getHours() + 2);
        const expiredAt = localStorage.getItem(key),
            expected = dt.getTime(),
            baseVer = BrandConfig.brandName + ".";

        if (!expiredAt) {
            localStorage.setItem(key, String(expected));
            return baseVer + expected;
        } else if (Number(expiredAt) < Date.now()) {
            localStorage.setItem(key, String(expected));
            return baseVer + expected;
        } else {
            return baseVer + expiredAt;
        }
    }

    pairs(data: any, type: string, price_type: string) {
        const pairs = _.filter(data, (pair: { type: any; price_type: any }) => pair.type == type && pair.price_type == price_type);
        return _.map(
            _.groupBy(pairs, (lang: { source_lang_name: any; }) => {
                return lang.source_lang_name;
            }),
            (lang: {
                source_lang_iso2: any;
                source_lang_name: any;
                source_lang: any;
                cc: any;
            }[]) => {
                const targets = _.map(lang, (target: { target_lang: any; }) => {
                    return target.target_lang;
                });
                return {
                    id: lang[0].source_lang,
                    name: lang[0].source_lang_name,
                    iso2: lang[0].source_lang_iso2,
                    cc: lang[0].cc,
                    target_languages: targets,
                };
            }
        );
    }
    path(href: string, locale: string, locales: any) {
        const supportedLocales = locales.replace(",", "|");

        return href.replace(
            new RegExp("/(" + supportedLocales + ")/"),
            '/' + locale + '/'
        );
    }
}

export default new Helpers();
